/* Desktop */
/* General navbar styles */
.navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    /* background-color: #EBF7FF; Adjust as needed */
    /* border-bottom: 1px solid #e4e4e7; Replace with the exact box color from shadcn */
    z-index: 1000;
    display: flex;
    /* align-items: center; */
    padding: 15px 20px;
    margin: 0; /* Remove any margin */
    flex-direction: row; /* Stack items vertically */
}

.navbar-fixed-phone {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    /* background-color: #EBF7FF; Adjust as needed */
    /* border-bottom: 1px solid #e4e4e7; Replace with the exact box color from shadcn */
    z-index: 1000;
    display: flex;
    /* align-items: center; */
    padding: 15px 10px;
    margin: 0; /* Remove any margin */
    flex-direction: row; /* Stack items vertically */
    height: 85px;
}

/* Container for logo and menu items */
.center-link-navbar {
    /* display: flex; */
    align-items: center;
    justify-content: flex-start; /* Align content to the left */
    width: 100vw;
    max-width: 1200px; /* Optional max width for alignment */
    margin: 0;
}

.navbar-vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

/* Vertical container for logo and menu items */
.navbar-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align items to the left */
    width: 100%;
    gap: 20px; /* Space between logo and menu items */
    margin-left: 20px;
    margin-top: 10px;
}


.navbar-container-phone {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align items to the left */
    width: 100%;
    gap: 5px; /* Space between logo and menu items */
    margin-left: 10px;
    margin-top: 20px
}

/* Logo styles */
.brand-loggo {
    margin-right: 25px; /* Space between logo and menu items */
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.brand-loggo-phone {
    width: 40px;
    margin-right: 5px; /* Space between logo and menu items */
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.logo-image {
    height: 35px; /* Adjust height as needed */
    width: auto;
    margin-left: 10px;
    margin-top: 5px;
}

.logo-image-phone {
    height: 25px; /* Adjust height as needed */
    width: auto;
    margin-left: 0px;
    margin-top: 0px;
}

/* Container for menu items */
.navbar-text-container {
    display: flex;
    align-items: center;
    gap: 25px; /* Space between items */
    background: white;
    border: none;
    border-radius: 24px;
    padding-left: 10px;
    padding-right: 10px;
    height: 51px;
  }

.navbar-text-container-phone {
    display: flex;
    align-items: center;
    gap: 3px; /* Space between items */
    background: white;
    border: none;
    border-radius: 24px;
    padding-left: 10px;
    padding-right: 10px;
    height: 51px;
    margin-left: auto; /* Push menu items to the far right */
  }
  
.icon-profile {
    padding-right: 4%
}

/* Menu item styles */
.navbar-text-content {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: black;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px 12px;
    text-decoration: none;
}

.navbar-text-content-phone {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: black;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px 5px;
    text-decoration: none;
}
  
.navbar-text-content svg {
    margin-right: 8px; /* Space between the icon and text */
}

.navbar-text-content:hover {
    /* text-decoration: underline; Optional underline on hover */
}

/* Selected menu item */
.navbar-text-content-selected {
    font-weight: bold;
    background-color: orange;
    border-radius: 22px;
    color: white
}



.center-link-navbar-phone {
    align-items: center;
}

.navbar-text-container-phone {
    margin-top: -15px;
    text-align: center;
}

.navbar-text-content-selected-phone {
    color: var(--orange)
}

.phone-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px; /* Adjust padding as needed */
}

.menu-icon {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    width: 30px;
    height: 30px;
    position: relative;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 1100;
}

.burger {
    width: 25px;
    height: 3px;
    background-color: var(--orange);
    transform: translate(-50%, -50%);
}

.navbar-links {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: var(--beige);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    
}

.navbar-links.show {
    transform: translateY(0%);
}

.mascott-image {
    height: 45px;
    width: auto;
    margin-top: 5px;
}

.profile-image {
    height: 48px;
    width: 48px;
    margin-top: 1.5px;
    object-fit:contain;
    border-radius: 50%; /* Makes the image circular */
    border: 2px solid white; /* White border around the image */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.profile-image-phone {
    height: 40px;
    width: 42px;
    object-fit:contain;
    border-radius: 50%; /* Makes the image circular */
    /* border: 1px solid white; White border around the image */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-top: -10px;
}

.icon-profile {
    padding-right: 4%
}


/* @media only screen and (max-width: 768px) {
    .navbar-fixed {
        height: 90px;
        width: 100%; 
        background-color: var(--blue);
    }

    .brand-loggo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 128px;
        background-color: white;
        margin-top: 0px;
    }

    .navbar-text-content {
        font-size: 26px;
        width: 100%;
        color: var(--blue);
        background-color: var(--beige);
        margin-top: 40px;
        border: none;
        font-weight: 500;
    }

    .navbar-links-container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .navbar-text-content-selected {
        color: var(--orange)
    }
} */