/* Style the datepicker container */
.custom-datepicker-calendar {
    width: 250px !important;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Style the months to make them larger */
  .react-datepicker__month-container {
    width: 250px;
    font-size: 14px;
    padding: 10px;
    border-color: transparent !important;
  }

  .react-datepicker__navigation {
    top: 10px;
}

.react-datepicker {
  border-radius: 15px !important; 
  border-width: 0.5px !important;
  border-color: lightgray !important;
}

.react-datepicker__header {
  border-bottom-width: 0.5px;
  border-color: transparent;
}

.react-datepicker-wrapper {
    border: 1px solid white; 
    background-color: white;
    border-radius: 20px;
    padding: 8px;
}

.react-datepicker__input-container {
    padding-left: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.react-datepicker-popper {
  left: -20px !important;
}

.react-datepicker__navigation--previous {
  left: 180px !important; 
  z-index: 1000;
}

.react-datepicker__navigation {
  top: 2px
}

.react-datepicker__navigation--next {
  right: calc(4vw + (8 / 100 * 92vw)) !important; /* 4% of window width + 8% of 92% of window width */
}


  .react-datepicker-year-header {
    background-color: white;
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    color: white;
    fill: white; 
    width: 0px;
  }

  .react-datepicker__month-text, 
  .react-datepicker__month {
    margin: 4px !important; 
    padding-top: 15px !important;
    padding-bottom: 15px !important
  }
  
  /* Override the selected month styles */
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__month-text--selected {
    background-color: white !important;
    color: black !important;
    border-radius: 8px;
  }

  .react-datepicker__month-text--in-range {
    border-radius: 8px !important;
    background-color: #F08300 !important;
    color: #fff !important;
}

.react-datepicker__month-text--selecting-range-end:not([aria-disabled=true]):hover {
    border-radius: 8px !important;
    background-color: #F08300 !important;
    color: #fff !important;
}

.react-datepicker__month-text--selecting-range-end {
    border-radius: 8px !important;
    background-color: #F08300 !important;
    color: #fff !important;
}

.react-datepicker__month-text--selecting-range-start {
    border-radius: 8px !important;
    background-color: #F08300 !important;
    color: #fff !important;
}

.react-datepicker__month-text--range-end {
    border-radius: 8px !important;
    background-color: #F08300 !important;
    color: #fff !important;
}

.react-datepicker__month-text--range-start {
    border-radius: 8px !important;
    background-color: #F08300 !important;
    color: #fff !important;
}

.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text-in-range, .react-datepicker__year-text-in-range) {
    border-radius: 8px !important;
    background-color: #FFF7EB !important;
    color: #F08300 !important;
}

.react-datepicker__month-text--in-selecting-range:not([aria-disabled=true]) {
    border-radius: 8px !important;
    background-color: #FFF7EB !important;
    color: #F08300 !important;
}

.react-datepicker__month-text-range:not([aria-disabled=true]):hover {
    border-radius: 8px !important;
    background-color: #FFF7EB !important;
    color: #F08300 !important;
}

.react-datepicker__month-text:not([aria-disabled=true]):hover {
  border-radius: 8px !important;
  background-color: #F08300 !important;
  color: #fff !important;
}

.react-datepicker__month-text--selecting-range-start:not([aria-disabled=true]) {
  border-radius: 8px !important;
  background-color: #F08300 !important;
  color: #fff !important;
}
  
  /* Style the months between the range */
  .between-month {
    background-color: #FFF7EB !important;
    color: black !important;
    border-radius: 8px;
  }
  
  /* Grid layout for the months */
  .react-datepicker__month-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 months per row */
    gap: 10px; /* Space between months */
    justify-content: center;
    align-items: center;
  }
  