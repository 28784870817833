.title-main-dashboard-container {
    display: flex;
    flex-direction: row;
    max-width: 1250px;
    width: 90%;
    align-items: center;
}

.table-row-hover:hover {
    cursor: pointer;
    background-color: #f9f9f9; /* Optional: Add a subtle background color change */
}

.main-title {
    color: black;
    font-size: 28px;
    text-align: start;
    margin-top: 10px;
    margin-left: 50px;
    margin-bottom: 20px;
}

.main-title-dashboard {
    color: black;
    font-size: 28px;
    text-align: start;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    flex-grow: 1;
}

.add-item-container {
    width: 40px; 
    height: 40px;
    background-color: var(--blue);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.add-item-text {
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.sub-title {
    color: black;
    font-size: 22px;
    text-align: start;
    margin-top: 50px;
    margin-left: 10px;
}

/* Technical dashboard */
.details-machine {
    display: flex;
    align-items: flex-start;
    margin-left: 50px;
    text-align: left;
}

.details-machine-content {
    margin-top: 5px;
    display: flex;
    font-size: 16px;
}

.menu-active {
    height: 50px; 
    align-items: center;
}

.details-machine-content-name-section {
    color: black;
}

.is-active-machine-container {
    margin-left: 5px; 
    height: 30px;
    background-color: lightgray;
    border-radius: 20px;
    border-width: 1px;
    border-color: darkgray;
}

.details-machine-content-value-section {
    font-weight: 600;
    color: black;
    margin-left: 5px;
}

.row-table-main-dashboard-container {
    flex: 1 1
}

@media only screen and (max-width: 768px) {
    .main-title {
        margin-top: 10px;
        margin-left: 20px;
        margin-bottom: 20px;
        font-size: 22px;
    }

    .main-title-dashboard {
        font-size: 28px;
    }

    .sub-title {
        font-size: 18px;
        margin-top: 20px;
    }

    .details-machine-content {
        display: flex;
        font-size: 14px;
    }
}

/* proper to the dashboard tables */

.row-container {
    min-height: 55px;
    display: flex;
    color: var(--blue);
}

.row-container-title {
    background-color: var(--blue);
}

.row-title {
    display: flex;
    flex: 1;
    background-color: var(--blue);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    align-items: center; 
    justify-content: center; 
    border-top: 1.5px solid var(--blue);
    border-bottom: 1.5px solid var(--blue);
    border-left: 3px solid var(--blue);
    border-right: 3px solid var(--blue);
}

.row-content {
    display: flex;
    flex: 1;
    cursor: pointer;
    background-color: var(--beige);
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    border-top: 1.5px solid var(--blue);
    border-bottom: 1.5px solid var(--blue);
    border-left: 3px solid var(--blue);
    border-right: 3px solid var(--blue);
}

.row-text {
    width: 85%;
    text-align: center;
    overflow-wrap: break-word; /* to allow text to wrap to the next line if it's too long */
    margin-top: 5px;
    margin-bottom: 5px;
}

.title-text-container {
    display: flex;
    flex: 1;
    width: 90%;
    text-align: center;
    overflow-wrap: break-word; /* to allow text to wrap to the next line if it's too long */
    margin-top: 5px;
    margin-bottom: 5px;
    color: #f08300;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.vertical-line {
    height: 100%;
    border-left: 3px solid var(--blue);
    width: 3px;
}

@media only screen and (max-width: 768px) {
    .table-complaint-dashboard {
        margin-top: 15px;
    }

    .title-text-container {
        font-size: 16px;
    }
}