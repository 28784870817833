@import url("https://fonts.googleapis.com/css2?family=Geist:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply font-sans antialiased bg-background text-foreground;
  }
}

input:focus {
  outline: none; /* Removes the default outline */
  box-shadow: none; /* Removes the box shadow used for the ring */
}

input:hover {
  cursor: pointer
}


html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #001B25;
}

:root {
  --orange: #f08300;
  --beige: #f9f2e0;
  --blue: #001B25;
  --light-orange: rgb(247, 196, 133);
  --extra-light-orange: rgb(247, 217, 179);
  --dark-beige: #f4e7c6;
  --light-beige: #faf6eb;
}

div {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none; /* Remove underline */
  color: black; /* Set your desired color */
}


button {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-family: "Poppins", sans-serif;
}

 /* Workaround to remove autofill color from inputs */
  input, select {
    -webkit-text-fill-color: var(--blue) !important;
    -webkit-background-clip: text !important;
    background-clip:  text !important;
  }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100%
}

.App-container {
  display: flex;
  height: 100%;
  width: 100%
}

.main-container {
  background-color: white;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.main-container-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.main-container-content {
  flex: 1;
}

.main-container-content-wrapper {
  margin-left: 40px;
  margin-bottom: 20px;
  flex: 1;
}

@media only screen and (min-height: 500px) {
  .main-container-content-wrapper {
    margin-bottom: 100px;
  }
}

.button-color-input {
  background-color: #f08300;
}

@media only screen and (max-height: 500px) {
  .main-container-content-wrapper {
    margin-bottom: 30px;
  }
}

.products-section {
  margin-bottom: 50px
}

.scrollable {
  overflow-y: scroll;
}

.horizontal-line-orders {
  margin-top: 30px;
  height: 2px;
  background-color: #001B25;
  margin-right: 290px
}

.topBar-main-container {
  height: 80px;
  background-color: var(--beige);
  width: 100%;
  display: flex
}

.topBar-left-container {
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
  .App-container {
    display: block;
    height: 100%;
    width: 100%
  }

  .topBar-main-container {
    height: 10px;
    background-color: var(--beige);
    width: 100%;
    display: flex
  }

  .main-container-content-wrapper {
    width: 90%;
    margin-left: 5%;
  }
}

/* Modal Content */
.modal-class {
  background-color: white;
  padding-top: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
  border-color: transparent !important;
}

.modal-class::after {
  border-color: transparent !important;
}

.modal-class::before {
  border-color: transparent !important;
}

.modal-class:hover {
  border-color: transparent !important;
}

/* Modal Overlay */
.overlay-class {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}